import type { GetAvatarUrlInput } from '@mntn-dev/files-shared'
import { toStringRecord } from '@mntn-dev/utilities'
import type { ClientFilesUtilitiesContext } from './context.ts'
import { logger } from './logger.ts'

export const getAvatarProxyUrl =
  ({ filesApiBaseUrl }: ClientFilesUtilitiesContext) =>
  ({ userId, options }: GetAvatarUrlInput) => {
    try {
      const paramObject = {
        userId,
        ...options,
      }
      const queryString = new URLSearchParams(
        toStringRecord(paramObject)
      ).toString()
      const result = `${filesApiBaseUrl}proxy/avatar?${queryString}`
      return result
    } catch (error) {
      logger.error('getAvatarProxyUrl', { error })
      throw error
    }
  }
